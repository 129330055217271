import React, { useRef } from 'react';
// import { Editor as BlogEditot } from '@tinymce/tinymce-react';
import BundledEditor from './BundledEditor'

function Editor({ value, onChange }) {
    const editorRef = useRef(null);
    return (
        <>
            <BundledEditor
                apiKey="dnxhjivwtsdxxh69dlrrazkd08pxxf96u30ylx8v1v0mxiub"
                value={value}
                // onInit={(evt, editor) => editorRef.current = editor}
                onEditorChange={(newvalue, editor) => {
                    onChange(newvalue, editor.getContent({ format: "html" }));
                }}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                        'searchreplace', 'table', 'wordcount'
                    ],
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    menubar: 'favs file edit view insert format tools table help',
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor link | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    // toolbar: "undo redo | link image | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                    branding: false,
                    // link_title: false,
                    noneditable_class: 'mceNonEditable',
                }}
            />
        </>
    )
}

export default Editor