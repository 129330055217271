import { Dialog, Slide, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState, useRef } from 'react'
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Editor from '../../components/elements/Editor';
import useCreate from '../../hooks/useCreate';
import useUploadMedia from '../../hooks/file/useUploadMedia';
import ReactDatePicker from "react-datepicker";
import toast from 'react-hot-toast';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true
function AddBlogs({ open, handleClose }) {
    const initialstate = {
        title: '',
        description: '',
        seo: {
          title: '',
          description: ''
        },
        published_at: '',
        content: '',
        thumbnail: {
            image_id: '',
            image_alt: '',
        }
      }
    const fileInputRef = useRef(null)
    const [blogFormData, setBlogFormData] = useState(initialstate)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [imagePreview, setImagePreview] = useState(null)
    const { setDataToServer, } = useCreate({
        refreshUrl: 'blogsListing',
        url: 'create-blog',
        onSuccess: () => { 
            setIsSuccess(true)
            toast.success("Blog Added Successfully",{
                position:'top-right',
                duration:3000
         
            })
            const timeout =   setTimeout(() => {
                    handleClose()
            }, 1500);

            return () => clearTimeout(timeout)
        },
        onError: () => { 
            setIsError(true)
            toast.error("Oops! Something went wrong",{
                position:'top-right',
                duration:3000
            })
        }
    })
    const handleBlogFormData = (e) => {
       
            let $this = e.target;
        
            let data = {
              ...blogFormData,
              [e.target.name]: $this.value,
            };
          
        setBlogFormData(data)
    }
    
    const handleBlogSeo = (e) => {
        let value = e.target.value;
        let tempName = e.target.attributes["data-name"].value;
        let data = {
            ...blogFormData,
            seo: {
                ...blogFormData?.seo,
                [tempName]: value,
            },
        };
        setBlogFormData(data);
    }

    const handleSubmitBlogFormData = (e) => {
        e.preventDefault()
        setDataToServer.mutate(blogFormData)
        
    }

    const handleChangeEditor = (e, value) => {
        let data = {
            ...blogFormData,
            content: value
        }
        setBlogFormData(data)
    }

    const closeErrorMessage = () => {
        setIsSuccess(false)
        setIsError(false)
    }

    const handleOpenFileUploadDialog = () => {
        fileInputRef.current.click()
    }
    const {upload} = useUploadMedia({
        url:'uploadImage',
        onSuccess:(res) => {
            const { image_url, image_id } = res?.data[0]
            let formDataCopy = blogFormData
            formDataCopy['thumbnail'].image_id = image_id
            setImagePreview(image_url)
            setBlogFormData({ ...blogFormData })
        }
    })
    // let uploadScreen;
    const handleUploadFile = (e) => {
        let thumbnail = e.target?.files[0]
        let data = {
            screen: 'blogs',
            thumbnail: [thumbnail]
        }
        upload.mutate(data)
    }

    const clearData = () => {
        setBlogFormData(initialstate)
        handleClose()
    }

    const handleSetPublishDate = (value) => {
        let data = {
            ...blogFormData,
            published_at: value
        }
        setBlogFormData({ ...data })
    }
    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={clearData} TransitionComponent={Transition} disableEnforceFocus={true} disableAutoFocus={true}>
            <div className='container_xxl px-5'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={clearData}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Add Blog</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleSubmitBlogFormData}>
                        <div className='grid grid-cols-1 gap-4'>
                      
                            <div>
                                <label htmlFor="title" className='label'>Blog Title <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Blog Title' value={blogFormData.title} onChange={handleBlogFormData} name='title' className='input mt-4' />
                            </div>
                            <div>
                                <label htmlFor="description" className='label'>Blog Description <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Blog Description' value={blogFormData.description} onChange={handleBlogFormData} name='description' className='input mt-4' />
                            </div>
                            {/* <div>
                            <label htmlFor="published_at" className='label'>Blog Date <small className="text-red-700">*</small></label>
                                <input type="date" name='published_at' onChange={handleDateBlogChange} value={blogFormData.published_at} className='input mt-4' />
                            </div> */}
                             <div>
                                <label htmlFor="Blog Title" className='label'>Blog Publish Date <small className="text-red-700">*</small></label>
                                {/* <input type="date" required={IS_REQUIRED} value={blogFormData.blogPublishDate} onChange={handleBlogFormData} name='blogPublishDate' className='input' /> */}
                                <div className='w-full '>
                                    <ReactDatePicker dateFormat="dd/MM/yyyy" required={IS_REQUIRED} selected={blogFormData.published_at} onChange={(date) => handleSetPublishDate(date)} className='input w-full' />
                                </div>
                            </div>
                          
                            <div className=''>
                                <label htmlFor="Blog Title" className='label'>Blog Banner Image <small className="text-red-700">*</small></label>
                                {
                                    upload.isLoading ? <>
                                    <Spinner/>
                                    </> : <>
                                    {imagePreview && <img src={imagePreview} alt="blog image" className='object-contain w-48 h-48 my-4' />}
                                    </>
                                }
                                
                                <div className='mt-5'>
                                    <Button variant="contained" color='success' className='primaryBtn-contained' onClick={handleOpenFileUploadDialog}>
                                        {imagePreview ? 'Update Banner Image ' : ' Add Banner Image'}
                                    </Button>
                                    <input ref={fileInputRef} type="file" className='hidden' onChange={(e)=>handleUploadFile(e)} />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Blog Content" className='label'>Blog Content <small className="text-red-700">*</small></label>
                                <div className='mt-5'>
                                <Editor value={blogFormData?.content} onChange={handleChangeEditor} />
                                </div>
                            </div>
                           
                            <div className='mt-3 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Search Engine Optimization</h1>
                            </div>
                            <div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Title</label>
                                    <input type="text"  placeholder='Meta Title' name="seo" data-name='title' className='input mt-4' value={blogFormData?.seo?.['title']} onChange={handleBlogSeo} />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Description</label>
                                    <input type="text"  placeholder='Meta Description' name="seo" data-name='description' className='input mt-4' value={blogFormData?.seo?.['description']} onChange={handleBlogSeo} />
                                </div>
                            </div>
                        </div>
                        {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.message}, Please try again later</Alert>}
                        {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Blog Created Succesfully</Alert>}
                        <div className='text-center my-6'>
                            {setDataToServer.isLoading ? <Spinner /> :
                                <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                    Publish Blog
                                </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default AddBlogs