import { PROJECT_NAME } from '../../config'
import './footer.css'

function Footer() {
  return (
    <div className='bg-black text-white text-center py-2 ml-[100px]'>
      <small>{PROJECT_NAME} Dashboard</small>
    </div>
  )
}

export default Footer