import React, { useState } from 'react'
import { downloadReports } from '../../apis/reports'
import Layout from '../../components/layout'
import { useQueryClient, useMutation } from "@tanstack/react-query";
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const IS_REQUIRED = true
function Reports() {
    const queryClient = useQueryClient()
    const initialstate = {
        start_date: '',
        end_date: '',
        report_type: '',
    }
    const [reportForm, setReportForm] = useState(initialstate)

    const handlereportForm = (e) => {
        let value = e.target.value
        let data = {
            ...reportForm,
            [e.target.name]: value
        }
        setReportForm(data)
    }

    const reportDownload = useMutation((data) => downloadReports(data), {
        onSuccess: (data) => {
            const href = URL.createObjectURL(new Blob([data.data]));
            let a = document.createElement('a');
            a.href = href;
            a.download = 'reports.xlsx';
            a.click();
        },
        onError: () => {
        }
    })

    const handleReportDownload = (e) => {
        e.preventDefault()
        reportDownload.mutate(reportForm)
    }
    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Products Reports</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <form onSubmit={handleReportDownload}>
                            <div className='grid grid-cols-4 gap-x-4'>
                                <div className='input-container'>
                                    <label htmlFor="Start Date" className='label'>Start Date</label>
                                    <input required={IS_REQUIRED} type="date" name='start_date' value={reportForm.start_date} onChange={handlereportForm} className='input' />
                                </div>
                                <div className='input-container'>
                                    <label htmlFor="End Date" className='label'>End Date</label>
                                    <input required={IS_REQUIRED} type="date" name='end_date' value={reportForm.end_date} onChange={handlereportForm} className='input' />
                                </div>
                                <div>
                                    <label htmlFor="report_for" className='label'>Reports For ?</label>
                                    <select required={IS_REQUIRED} name="report_type" className='input' value={reportForm.report_type} onChange={handlereportForm}>
                                        <option value="">--Select Reports For--</option>
                                        <option value="BUY">Products Sold</option>
                                        <option value="VIEWED">Products Viewed</option>
                                    </select>
                                </div>
                                <div className='flex items-center justify-center'>
                                    {reportDownload.isLoading ? <Spinner /> :
                                        <button className='flex items-center justify-center space-x-3 bg-[color:var(--color3)] text-white px-4 py-2 rounded font-semibold'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                                            </svg>
                                            <span>Download Report</span>
                                        </button>}
                                </div>
                            </div>
                        </form>
                        {reportDownload.isError && <Alert className='mb-4' severity="error" sx={{ width: '100%' }}>{reportDownload.error.message}, Please try again later</Alert>}
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Reports