import { FormControlLabel, Switch } from "@mui/material"

const SwitchToggle = (props) => {
    const { className, onChange, label, value, checkedValue, name } = props
    return (
        <FormControlLabel
            value={value}
            control={<Switch onChange={onChange} color="primary" name={name} />}
            label={label}
            labelPlacement="start"
            inputProps={{ 'aria-label': 'controlled' }}
            checked={checkedValue}
        />
    )
}

export default SwitchToggle