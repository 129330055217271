import React, { useRef, useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import { GrAdd } from 'react-icons/gr';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import useConfigImageUpload from '../../../hooks/useConfigImageUpload';
import { useMutation } from '@tanstack/react-query';
import useCreate from '../../../hooks/useCreate';
import MuiAlert from '@mui/material/Alert';

const initialIdvProduct = {
  selling_price: "",
  mrp_price: "",
  product_id: "",
  product_map_id: "",
  quantity: "",
  status: "",
  attributes: [],
  attribute_value_id: '',
  images_of_product: [],
  images_of_product_preview: []
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditIdvGeneratedProduct = ({ open, onClose, editData, name }) => {
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [idvProduct, setIdvProduct] = useState(initialIdvProduct)
  const productImageUpload = useRef(null)

  const { setDataToServer: updateConfig } = useCreate({
    refreshUrl: 'getConfigList',
    url: 'updateConfig',
  })

  const { setDataToServer: uploadImage } = useConfigImageUpload({
    refreshUrl: '',
    url: "uploadImage"
  })

  const handleClickOpenFileDialog = () => {
    productImageUpload.current.click()
  }

  const handleUploadImages = (e) => {
    uploadImage.mutate({
      product_image: e.target.files[0]
    }, {
      onSuccess: ({ data }) => {
        const mediaId = data.mediaId
        let previewImag = []
        for (let i = 0; i < e.target.files.length; i++) {
          previewImag.push(URL.createObjectURL(e.target.files[i]))
        }
        let temp = { ...idvProduct }
        temp.images_of_product = [...temp.images_of_product, mediaId]
        temp.images_of_product_preview = [...temp.images_of_product_preview, ...previewImag]
        setIdvProduct(temp)
      }
    })

  }

  const handleAddFromData = (e, file, isFileObject, name) => {
    let data;
    if (file) {
      if (isFileObject) {
        const tempFileURL = URL.createObjectURL(e)
        data = {
          ...idvProduct,
          [name]: e,
          [name + '_preview']: tempFileURL
        }
      } else {
        let fileObject = e.target.files[0]
        data = {
          ...idvProduct,
          [e.target.name]: fileObject
        }
      }
    } else {
      if (e.target.name === 'seo') {
        let value = e.target.value
        let tempName = e.target.attributes['data-name'].value
        data = {
          ...idvProduct,
          seo: {
            ...idvProduct?.seo,
            [tempName]: value
          }
        }
      } else {
        let value = e.target.value
        data = {
          ...idvProduct,
          [e.target.name]: value
        }
      }
    }
    setIdvProduct(data)
  }

  const handleRemoveSelectedProductImage = (e, id) => {
    e.stopPropagation();
    let productData = { ...idvProduct }
    productData?.images_of_product_preview.splice(id, 1)
    productData?.images_of_product.splice(id, 1)
    setIdvProduct({ ...productData })
  }

  const handleFormSubmit = () => {
    updateConfig.mutate(idvProduct, {
      onSuccess: () => {
        setIsError(false)
        setIsSuccess(true)
        setTimeout(() => {
          onClose()
          setIsError(false)
          setIsSuccess(false)
        }, 2000)
      },
      onError: () => {
        setIsError(true)
        setIsSuccess(false)
      }
    })
  }

  useEffect(() => {
    if (editData) {
      const data = {
        ...editData,
        images_of_product: editData?.images_of_product.map((it) => { return it.media_id }),
        images_of_product_preview: editData?.images_of_product.map((it) => { return it.path })
      }
      setIdvProduct(data)
    }
  }, [editData])

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="border-none outline-none">
      <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2 focus:outline-none'>
        <div className=' max-w-5xl mx-auto bg-white'>
          <div className=' divide-y'>
            <div className="head-modal">

              <div className='flex items-center space-x-4 px-6'>
                <div>
                  <IconButton onClick={onClose}>
                    <ArrowBackIcon />
                  </IconButton>
                </div>
                <h2 className='heading text-3xl'>Edit Product Variation</h2>
              </div>
            </div>
            <div className="body-modal py-4 px-6 overflow-y-scroll h-[85vh]">
              <div className='grid grid-cols-1 gap-4'>
                <div>
                  <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                  <input required type="text" placeholder='Product Name' name='product_name' className=' input' disabled value={name} />
                </div>
                <div>
                  <label htmlFor="Product MRP Price" className='label'>Product MRP Price <small className="text-red-700">*</small></label>
                  <input required type="text" placeholder='Product MRP Price' name='mrp_price' className=' input' value={idvProduct.mrp_price} onChange={handleAddFromData} />
                </div>
                <div>
                  <label htmlFor="Product Selling Price" className='label'>Product Selling Price <small className="text-red-700">*</small></label>
                  <input required type="text" placeholder='Product Selling Price' name='selling_price' className=' input' value={idvProduct.selling_price} onChange={handleAddFromData} />
                </div>
                <div>
                  <label htmlFor="Product Quantity" className='label'>Product Quantity <small className="text-red-700">*</small></label>
                  <input required type="text" placeholder='Product Quantity' name='quantity' className=' input' value={idvProduct.quantity} onChange={handleAddFromData} />
                </div>
                <div>
                  <label className='label'>Status</label>
                  <select name="status" className='input' value={idvProduct.status} onChange={handleAddFromData}>
                    <option value="">--Select--</option>
                    <option value={'1'}>Enable</option>
                    <option value={'0'}>Disable</option>
                  </select>
                </div>
              </div>
              <div className='mb-2'>
                <h2 className=' text-xl heading'>Product Images</h2>
                <div className='.relative cursor-pointer'>
                  <div className='imagePreview__container relative z-10'>
                    <div className="grid grid-cols-4 gap-4 ">
                      {idvProduct?.images_of_product_preview.map((img, idx) => {
                        return <div className=' w-full h-48 overflow-hidden border p-2 relative cursor-default' key={idx}>
                          <div className='w-6 h-6 cursor-pointer float-right' onClick={(e) => handleRemoveSelectedProductImage(e, idx)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <img src={img} alt="img" className='w-full h-full object-contain' />
                        </div>
                      })}
                      <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                        <GrAdd className=' w-16 h-16 text-gray-400' />
                        <small>Add Product Image</small>
                      </div>
                    </div>
                  </div>
                  {idvProduct?.images_of_product_preview.length <= 0 && <span className='text-gray-400 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Click here to Upload</span>}
                </div>
                <input type="file" className='hidden' ref={productImageUpload} onChange={handleUploadImages} multiple accept="image/jpeg, image/png, image/jpg" />
              </div>
              {isError && <Alert onClose={() => { setIsError(false); setIsSuccess(false) }} className='mb-4' severity="error" sx={{ width: '100%' }}>{updateConfig?.error?.message}, Please try again later</Alert>}
              {isSuccess && <Alert onClose={() => { setIsError(false); setIsSuccess(false) }} className='mb-4' severity="success" sx={{ width: '100%' }}>Product Updated Succesfully</Alert>}
              <div className="foot-modal my-2 text-center">
                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleFormSubmit}>Edit Product Variation</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditIdvGeneratedProduct