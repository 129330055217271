import { formatCount } from '../../utilities'
import './widgets.css'
function CardWidgets({label,border,count,description}) {
  return (
    <div className={`cardwidgets__container shadow-md rounded-md px-6 border-b-4 ${border} bg-white`}>
        <div className="cardwidgets_head border-b">
            <h2>{label}</h2>
        </div>
        <div className="cardwidgets_body py-6">
            <div className={`cardwidgets__counter border-4 ${border}`}>
                <span className='text-gray-700 font-semibold text-3xl'>{formatCount(count,true,1)}</span>
            </div>
        </div>
        <div className="cardwidgets_foot pb-6">
            <p className=' text-center text-lg text-gray-700 font-semibold capitalize'>{description}</p>
        </div>
    </div>
  )
}

export default CardWidgets