import React from 'react'
import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { deleteCoupon } from '../../../apis/coupon';
import toast from 'react-hot-toast'

function DeleteCoupon({ open, onClose, id, onDelete }) {
    const queryClient = useQueryClient()
    const deleteCategoryFromServer = useMutation((id) => deleteCoupon(id), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('couponList')
                onDelete()
                toast.success("Coupon Deleted Successfully",{
                    position:"top-right",
                    duration:3000
                })
            }
        },
    })
    const handleDeleteCategory = (e) => {
        e.preventDefault()
        deleteCategoryFromServer.mutate(id)
    }
    return (
        <>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose}>
                <div className=' bg-white w-full px-5'>
                    <div className='modal-head flex items-center justify-end'>
                        <div onClick={onClose}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <form className=' space-y-4' onSubmit={handleDeleteCategory}>
                        <div className=' space-y-7'>
                            <div>
                                <h1 className=' text-2xl text-red-700 font-semibold text-center'>Are You Sure You Want To Delete?</h1>
                            </div>
                        </div>
                        <div className=' space-x-4 text-center pb-5'>
                            <Button type='submit' variant="contained" color={`error`}>Delete</Button>
                            <Button variant="outlined" color='info' onClick={onClose}>Close</Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default DeleteCoupon