import { useEffect } from "react";
import { Link } from "react-router-dom"
import { handleLogoutFromServer } from "../../apis/logout";
import { useNavigate } from 'react-router-dom'
import './header.css'
import IdleTimer from "../../helper/idleTimer";
import { IDLE_TIME_IN_SECONDS, PROJECT_NAME } from "../../config";
let role = sessionStorage.getItem('roles');
function Header() {
    const navigate = useNavigate()
    const from = "/login";
    const handleLogout = () => {
        handleLogoutFromServer()
            .then((res) => {
                if (res.status === 200) {
                    sessionStorage.clear();
                    // window.location.replace("/login")
                    navigate(from, { replace: true });
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        const timer = new IdleTimer({
            timeout: IDLE_TIME_IN_SECONDS, //expire after 10 seconds
            onTimeout: () => {
                handleLogout()
            },
            onExpired: () => {
                //do something if expired on load
                handleLogout()
            }
        });

        return () => {
            timer.cleanUp();
        };
    }, []);

    return (
        <header className=" bg-[color:var(--color2)] sticky top-0 z-20">
            <div className="container_xxl">
                <div className="row items-center justify-between">
                    <div>
                        <Link to={`/`} className='text-white font-semibold text-4xl'>{PROJECT_NAME} Admin</Link>
                    </div>
                    <div>
                        <ul className="flex items-center space-x-8 text-white font-semibold text-base py-2">
                            <li className="linkItem">
                                <Link to={`/`}>Dashboard</Link>
                            </li>
                            <li className="linkItem">
                                <Link to={`/products`}>Products</Link>
                            </li>
                            <li className="linkItem">
                                <Link to={`/orders`}>Orders</Link>
                            </li>
                            <li className="linkItem">
                                <Link to={`/taxes`}>Taxes</Link>
                            </li>
                            <li className="linkItem">
                                <Link to={`/customers`}>Customers</Link>
                            </li>
                            <li className="linkItem">
                                <Link to={`/reports`}>Reports</Link>
                            </li>
                            <li className="linkItem">
                                <Link to={`/coupons`}>Coupons</Link>
                            </li>
                            {role === 'idigitize' &&
                                <li className="linkItem">
                                    <Link to={`/blogs`}>Blogs</Link>
                                </li>}
                            <li>
                                <button onClick={handleLogout} className="px-4 py-2 rounded text-white bg-[color:var(--color3)]">Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header