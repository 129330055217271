import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../apis/axios";
import { TOKEN_KEY } from "../../config";
import toast from "react-hot-toast";


// categorydelete
// coupondelete
const useDelete = ({
    refreshUrl = '',
    url = '',
    onSuccess,
    onError,
    successToastMessage
}) => {
    const queryClient = useQueryClient()
    const setDataToServer = useMutation((data) => setUsers(data), {
        onError: (err) => {
            onError?.(err)
        },
        onSuccess: (data) => {
            toast.success(successToastMessage)
            if (refreshUrl)
                queryClient.invalidateQueries([refreshUrl])
            onSuccess?.(data)
        }
    })
    const setUsers = async (getFormData) => {
        let TOKEN = sessionStorage.getItem(TOKEN_KEY);
        let fd = new FormData()
        for (var key in getFormData) {
            if (Array.isArray(getFormData[key]) || typeof getFormData[key] === 'object') {
                fd.append(key, JSON.stringify(getFormData[key]));
            } else {
                fd.append(key, getFormData[key]);
            }
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }

    const handleDelete = (data) => {
        setDataToServer.mutate(data)
    }
    return { setDataToServer,handleDelete }
}

export default useDelete