import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import React, { useState } from 'react';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { handleUpdateProductStatus } from '../../../apis/product';
import Spinner from '../../../components/spinner';
import Errors from '../../errors';
import MuiAlert from '@mui/material/Alert';
import { Link, useSearchParams } from 'react-router-dom';
import useRead from '../../../hooks/useRead';
import Pagination from '../../../components/elements/pagination';
import useReadUpdated from '../../../hooks/updated/useRead';
import DeleteModal from '../../../components/elements/deleteModal';
import useDelete from '../../../hooks/product/useDelete';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Products() {
    const [searchParams, setSearchParams] = useSearchParams();
    let initialData = {
        pageNo: searchParams.get('pageNo') || 0,
        limit: searchParams.get('limit') || 50,
        searchQuery: searchParams.get('searchQuery') || '',
        category_id: searchParams.get('category_id') || ''
    }
    const queryClient = useQueryClient()
    const [productVisibiliyId, setProductVisibiltyId] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)



    const changeStatus = useMutation((data) => handleUpdateProductStatus(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('productlist')
                setProductVisibiltyId(0)
            }
        },
    })
    const handleChangeStatus = (e, id) => {
        setProductVisibiltyId(id)
        let status = e.target.checked
        let data = {
            id,
            status
        }
        changeStatus.mutate(data)
    }
    const { data, paramsObject, setGetListParams } = useRead({
        url: 'productlist',
        initialData
    })
    const [searchVal, setSearcVal] = useState('');
    const limit = 50
    const handleSearchVal = (e) => {
        let value = e.target.value
        let copyPrams = {
            ...paramsObject,
            searchQuery: value
        }
        setSearchParams(copyPrams)
        setGetListParams(copyPrams)
        // setSearcVal(value)
    }
    const totalCount = data?.data?.total_count
    const handlePageClick = (val) => {
        let pageNo = {
            ...paramsObject,
            pageNo: val.selected
        };
        setCurrentPage(val.selected)
        setGetListParams(pageNo)
        setSearchParams(pageNo)
    }

    const handleSetLimit = (e) => {
        let value = e.target.value
        let copyPrams = {
            ...paramsObject,
            limit: value
        }
        setSearchParams(copyPrams)
        setGetListParams(copyPrams)
    }

    const { list: categoryList } = useReadUpdated({
        url: 'categorylist',
        initialData,
    })


    const handleFilterByCategory = (e) => {
        let data = {
            ...paramsObject,
            category_id: e.target.value
        };
        setGetListParams(data)
        setSearchParams(data)
    }

    const [products, setProducts] = useState(null);
  const [isDeleteModalOpen,setDeleteModalOpen] = useState(false)
  const handleDelete = (productId) => {
    setProducts(productId);
    setDeleteModalOpen(true)
  };
  const {handleDelete:onDeleteProduct} = useDelete({
    url:'productdelete',
    refreshUrl: 'productlist',
    successToastMessage:'Product Deleted Successfully',
    onSuccess: () => {
        setDeleteModalOpen(false)
    }
  })
    return (
        <>
            <div className='category px-4'>
                <div className='category__head headingBorder__b'>
                    <h2 className='titleHeading'>Products</h2>
                </div>
                <div className='category_table py-5 space-y-5'>
                    <div className='flex items-end space-x-4'>
                        <div className='flex-1'>
                            <input onChange={handleSearchVal} value={paramsObject.searchQuery} type="text" placeholder='Search By Key Word....' className='input' />
                        </div>
                        <div>
                            <Link to={`add-product`} className='block'>
                                <Button variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
                                    Add Product
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <label htmlFor="" className='label'>Limit</label>
                            <select name="limit" value={data.data?.per_page} className='input' onChange={handleSetLimit}>
                                {Array(5).fill(1).map((_, idx) => {
                                    return <option key={idx} value={(idx + 1) * 10}>{(idx + 1) * 10}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    {data.isLoading || data.isFetching
                        ? <Spinner />
                        : data.status === "error"
                            ? <Errors errorObject={data.error} inline />
                            : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                ID
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Product Image
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Category
                                                <select className='input mt-2' value={paramsObject.category_id} onChange={handleFilterByCategory}>
                                                    <option value="">--Filter By Category--</option>
                                                    {
                                                        categoryList?.data?.data?.map((it) => {
                                                            return (
                                                                <option key={it.id} value={it.id}>{it.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                SKU
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Price
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Quantity
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Visibility
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.data?.data?.map((productData, idx) => {
                                            const { image_path, product_code, product_name, product_type, home, category_name, product_id, status, id, mrp_price, quantity, sku } = productData
                                            return <tr key={idx}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {/* {paramsObject.limit * +paramsObject.pageNo - (paramsObject.limit - (idx + 1))} */}
                                                        {paramsObject.limit * paramsObject.pageNo + idx + 1}

                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <div className=' w-20 h-20 mx-auto p-4'>
                                                        <img src={image_path} alt={`${product_name}`} className='w-full h-full object-contain' />
                                                    </div>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {product_name}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {category_name}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {sku}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {mrp_price}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {quantity}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                    <div className='flex items-center justify-center'>
                                                        {id === productVisibiliyId ? <Spinner className={'w-8 h-8'} /> :
                                                            <>
                                                                <span className='text-gray-700 text-sm'>Hide</span>
                                                                <Switch inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => handleChangeStatus(e, id)} className='hide_show_switch' checked={status} />
                                                                <span className='text-gray-700 text-sm'>Show</span></>}
                                                    </div>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                    <div className='flex items-center justify-center'>
                                                        <Link to={`edit-product/${id}`}>
                                                            <IconButton>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Link>
                                                        <div onClick={() => handleDelete(id)}>
                                                                <IconButton>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                    <div>
                        <Pagination
                            currentPage={+paramsObject.pageNo}
                            lengthofItems={data.data?.total_count}
                            limit={paramsObject.limit}
                            onPageChange={handlePageClick}
                        />
                    </div>
                </div>
            </div>
            {/* <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Product Deleted Succesfully
                </Alert>
            </Snackbar> */}

            <DeleteModal
            open={isDeleteModalOpen} 
            handleClose={() => setDeleteModalOpen(false)}
            title={'Product Delete'}
            message={'Are you sure you want to delete this product?'}
            onDelete={() => onDeleteProduct({id:products})}
            />
        </>
    )
}

export default Products