const COUNT_ABBRS = [ '', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y' ];
export const formatCount = (count, withAbbr = false, decimals = 2) => {
    const i     = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
    let result  = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
    if(withAbbr) {
        result += `${COUNT_ABBRS[i]}`; 
    }
    return result;
}

export const formatDate = (unixTimeStamp) => {
    const date = new Date(unixTimeStamp * 1000)
    if(!date) return 
    const formatedDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    return formatedDate
  }