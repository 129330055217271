import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUploader } from "react-drag-drop-files";
import Button from '@mui/material/Button';
import { getCategoryList } from '../../../apis/category';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { editProduct } from '../../../apis/product';
import Spinner from '../../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { GrAdd } from 'react-icons/gr'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Configurations from '../../../components/section/configurations';
import ProductDetails from '../../../components/elements/ProductDetails/ProductDetails';
import useReadUpdated from '../../../hooks/updated/useRead';
import useRead from '../../../hooks/useRead';
import Loader from '../../../components/loader';
import Layout from '../../../components/layout';
import Summary from '../../../components/section/configurations/summary/Summary';
import GeneratedProducts from './GeneratedProducts';
import useCreate from '../../../hooks/useCreate';
import useUploadMedia from '../../../hooks/file/useUploadMedia';
import toast from 'react-hot-toast'
import deBounce from '../../../hooks/useDebounce';
import Editor from '../../../components/elements/Editor';
import { ReactSortable } from "react-sortablejs";

const fileTypes = ["JPG", "PNG", "JPEG"];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true
function EditProduct({ }) {
    const initialData = {
        pageNo: 0,
        limit: 50,
        searchQuery: ''
    }
    const navigate = useNavigate()
    const { id } = useParams()
    const productImageUpload = useRef(null)
    const [brandListOptions, setBrandListOptions] = useState([])
    const [frameListOptions, setFrameListOptions] = useState([])
    const [genderListOptions, setGenderListOptions] = useState([])
    const [taxListOptions, setTaxListOptions] = useState([])
    const queryClient = useQueryClient()
    const [productData, setProductData] = useState(null)
    const [slugCheck, setSlugCheck] = useState()
    const [slug, setSlug] = useState()

    const { paramsObject: editProductData } = useReadUpdated({
        url: 'getProductDetails',
        initialData: {
            id: id
        },
        onSuccess: (res) => {
            let productDetail = res?.product_details[0]
            setProductData(productDetail)
        }
    })
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)


    const handleAddFromData = (e, file, isFileObject, name) => {
        let data;
        if (e.target.name === "product_name") {
            let newval = e.target.value
            newval = newval.replace(/\s+/g, '-').toLowerCase();
            let sanval = sanitizetext(newval)
            let data = {
                ...productData,
                slug: sanval,
                product_name: e.target.value
            }
            setProductData(data)
            setSlug(data)
            let senddata = {
                slug: sanval
            }
            deBounce((e) => callapi(e, senddata), senddata)
            return;
        } else {
            if (file) {
                if (isFileObject) {
                    data = {
                        ...productData,
                        [name]: e
                    }
                } else {
                    let fileObject = e.target.files[0]
                    data = {
                        ...productData,
                        [e.target.name]: fileObject
                    }
                }
            } else {
                if (e.target.name === 'seo') {
                    let value = e.target.value
                    let tempName = e.target.attributes['data-name'].value
                    data = {
                        ...productData,
                        seo: {
                            ...productData?.seo,
                            [tempName]: value
                        }
                    }
                } else {
                    let value = e.target.value
                    data = {
                        ...productData,
                        [e.target.name]: value
                    }
                }
            }
        }
        setProductData(data)
    }
    const slugInput = (e) => {
        let val = e.target.value
        val = val.replace(/\s+/g, '-').toLowerCase();
        let sanval = sanitizetext(val)
        let data = {
            ...productData,
            slug: sanval
        }
        // setSlug(sanval)
        setProductData(data)
        setSlug({ ...data })
        let senddata = {
            slug: sanval
        }
        deBounce((e) => callapi(e, senddata), senddata)
    }

    const postProductDataToServer = useMutation((data) => editProduct(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('productList')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                }, 2000)
                toast.success("Product Edited Successfully", {
                    position: "top-right",
                    duration: 3000
                })
            }
        },
        onError: () => {
            setIsError(true)
            toast.error("Oops! Someting Went Wrong", {
                position: "top-right",
                duration: 3000
            })
        }
    })
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const { setDataToServer } = useCreate({
        url: 'productupdate',
        refreshUrl: 'productlist',
        onSuccess: () => {
            toast.success("Product Updated Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })

    function sanitizetext(intext) {
        const noSpecialChars = intext.replace(/[^a-zA-Z0-9\-]/g, '');
        // urlcheck(noSpecialChars)
        return noSpecialChars
    }
    const callapi = (e, data) => {
        checkSlugData.mutate(data)
    }


    const { setDataToServer: checkSlugData } = useCreate({
        refreshUrl: undefined,
        url: 'checkSlugData',
        onSuccess: (data) => {
            console.log('data', data)
            setSlugCheck(false)
            // setIsSuccess(true)
        },
        onError: (err) => {
            setSlugCheck(true)
            // setIsError(true)
        }
    })
    const handleProductSubmit = (e) => {
        e.preventDefault()
        setDataToServer.mutate(productData)
    }
    const handleCloseAndReset = () => {
        closeErrorMessage()
    }
    const handleuploadAgain = () => {
        let data = {
            ...productData,
            product_thumbnail_preview: '',
            product_thumbnail: '',
        }
        setProductData(data)
    }
    // useQuery(['categoryList'], getCategoryList, {
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         setCategoryList(data.data)
    //     }
    // })
    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])


    const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
    const handleOpenUploadModal = () => {
        setOpenUploadImagesModal(true)
    }
    const handleCloseUploadImageModal = () => {
        setOpenUploadImagesModal(false)
    }
    const handleClickOpenFileDialog = () => {
        productImageUpload.current.click()
    }
    const handleUploadImages = (e) => {
        let previewImag = []
        for (let i = 0; i < e.target.files.length; i++) {
            previewImag.push(URL.createObjectURL(e.target.files[i]))
        }
        let data = {
            ...productData,
            images_of_product: [...productData?.images_of_product, ...e.target.files],
            images_of_product_preview: [...productData?.images_of_product_preview, ...previewImag]
        }
        setProductData(data)
    }

    const handleRemoveSelectedProductImage = (e, id) => {
        e.stopPropagation();
        let predData = productData
        predData.images_of_product_preview.splice(id, 1)
        predData.images_of_product.splice(id, 1)
        setProductData({ ...productData })
    }

    const [openConfigModal, setOpenConfigModal] = useState(false)
    const handleOpenConfigurationModal = () => {
        setOpenConfigModal(true)
    }

    const { list: categoryList } = useReadUpdated({
        url: 'categorylist',
        initialData,
    })
    const { list: brandList } = useReadUpdated({
        url: "BrandListing",
        initialData,
    })

    let uploadScreen;
    const { upload } = useUploadMedia({
        url: 'uploadImage',
        refreshUrl: '',
        onSuccess: (res) => {
            console.log('res :>> ', res);
            let productCopy = { ...productData }
            if (uploadScreen === 'thumbnail') {
                productCopy['thumbnail'] = { image_alt: '', ...res.data[0] }
            } else {
                let copyPrductAdditionalImage = productCopy['additional_product_image']
                copyPrductAdditionalImage = [...productCopy['additional_product_image'], ...res.data.map((addImg) => {
                    return { image_alt: '', ...addImg }
                })]
                productCopy['additional_product_image'] = copyPrductAdditionalImage
            }
            setProductData(productCopy)
        }
    })
    const handleUploadFile = (e, screen) => {
        uploadScreen = screen
        let data = {
            screen: 'products',
            thumbnail: [...e]
        }
        upload.mutate(data)
    }
    const handleChangeThumbImageAlt = (e) => {
        let productCopy = { ...productData }
        productCopy['thumbnail'][e.target.name] = e.target.value
        setProductData(productCopy)
    }
    // console.log('productData', productData)
    const handleRemoveProductImage = (id) => {
        let productCopy = { ...productData }
        productCopy.additional_product_image = productCopy.additional_product_image.filter(x => x.image_id !== id)
        setProductData(productCopy)
    }

    const handleChangeEditor = (e, value) => {
        let data = {
            ...productData,
            description: value
        }
        setProductData(data)
    }

    const setProductDetailsList = (val) => {
        let newData = { ...productData };
        newData.additional_product_image = val;
        setProductData(newData);
    }


    const handleCheckBox = (e) => {
        let data = {
            ...productData,
            [e.target.name]: e.target.checked
        }
        setProductData(data)
    }

    return (
        <>
            <div className='container_xxl relative z-10 px-4'>
                <div className='flex items-center space-x-4 headingBorder__b'>
                    <div>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Edit Product</h1>
                    </div>
                </div>
                {
                    editProductData.isLoading ? (
                        <Loader />
                    ) : (
                        <div className='form-body py-8'>
                            <form className='' autoComplete='off' onSubmit={handleProductSubmit}>
                                <div className='grid grid-cols-1 gap-4'>
                                    <div className='mb-3 flex items-center justify-between border-b border-gray-400'>
                                        <h1 className='font-semibold text-2xl pb-3'>Product Listing</h1>
                                    </div>
                                    <div>
                                        <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                                        <input required type="text" placeholder='Product Name' name='product_name' className='input' value={productData?.product_name} onChange={(e) => handleAddFromData(e)} />
                                    </div>
                                    <div>
                                        <label htmlFor="Slug" className='label'>Slug <small className="text-red-700">*</small></label>
                                        <input required type="text" placeholder='Slug' name='slug' className='input' value={productData?.slug} onChange={(e) => slugInput(e)} />
                                        {slugCheck ? <p class="slugerror text-red-600 text-base mb-6 mt-6 font-semibold" id="slugerror">Slug already exist</p> : " "}
                                    </div>
                                    <div className='flex justify-center items-center space-x-5'>
                                        <div className='w-full'>
                                            <label htmlFor="Product Short Name" className='label'>Product Short Name <small className="text-red-700">*</small></label>
                                            <input type="text" placeholder='Product Short Name' name='short_name' className='input' value={productData?.short_name} onChange={handleAddFromData} />
                                        </div>
                                        <div className='w-full'>
                                            <label className='label'>Brand <small className="text-red-700">*</small></label>
                                            <select required name="brand_id" className='input' value={productData?.brand_id} onChange={handleAddFromData}>
                                                <option value="">--Select--</option>
                                                {
                                                    brandList.data?.data?.map((it) => {
                                                        return (
                                                            <option key={it.id} value={it.id}>{it.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className='grid grid-cols-1 gap-4'>
                                    <div className='mb-3 mt-10 flex items-center justify-between border-b border-gray-400'>
                                        <h1 className='font-semibold text-2xl pb-3'>Product Info</h1>
                                    </div>
                                    <div>
                                        <label htmlFor="Product Title" className='label'>Title <small className="text-red-700">*</small></label>
                                        <input type="text" placeholder='Product Name' name='title' className='input' value={productData?.title} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label htmlFor="Product Subtitle" className='label'>Subtitle <small className="text-red-700">*</small></label>
                                        <input type="text" placeholder='Product Name' name='subtitle' className='input' value={productData?.subtitle} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label htmlFor="Description" className='label'>Description <small className="text-red-700">*</small></label>
                                        <Editor value={productData?.description} onChange={handleChangeEditor} />
                                        {/* <textarea name="description" className=' min-h-[10rem] input' value={productData?.description} onChange={handleAddFromData}></textarea> */}
                                    </div>
                                    <div>
                                        <label htmlFor="Product Short description" className='label'> Short Description <small className="text-red-700">*</small></label>
                                        <textarea name="short_description" className=' min-h-[10rem] input' value={productData?.short_description} onChange={handleAddFromData}></textarea>
                                    </div>
                                </div>
                                <div>
                                    <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                        <h1 className='font-semibold text-2xl pb-3'>Product Details</h1>
                                    </div>
                                    <div className=' space-y-7'>
                                        <div className={`grid grid-cols-3 gap-4`}>
                                            <div>
                                                <label className='label'>Price <small className="text-red-700">*</small></label>
                                                <input required type="text" placeholder='Price' name='mrp_price' className='input' value={productData?.mrp_price} onChange={handleAddFromData} />
                                            </div>
                                            <div>
                                                <label className='label'>Quantity <small className="text-red-700">*</small></label>
                                                <input required type="text" placeholder='quantity' name='quantity' className='input' value={productData?.quantity} onChange={handleAddFromData} />
                                            </div>
                                            <div>
                                                <label className='label'>SKU <small className="text-red-700">*</small></label>
                                                <input required type="text" placeholder='SKU' name='sku' className='input' value={productData?.sku} onChange={handleAddFromData} />
                                            </div>
                                            <div>
                                                <label className='label'>Category <small className="text-red-700">*</small></label>
                                                <select required name="category_id" className='input' value={productData?.category_id} onChange={handleAddFromData}>
                                                    <option value="">--Select--</option>
                                                    {
                                                        categoryList.data?.data?.map((it) => {
                                                            return (
                                                                <option key={it.id} value={it.id}>{it.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <label className='label'>Selling Price</label>
                                                <input type="text" placeholder='Selling Price' name='selling_price' className='input' value={productData?.selling_price} onChange={handleAddFromData} />
                                            </div>
                                            <div>
                                                <label className='label'>Discount</label>
                                                <input type="text" placeholder='Discount' name='discount' className='input' value={productData?.discount} onChange={handleAddFromData} />
                                            </div>
                                            <div>
                                                <label className='label'>Is Non-Veg ? <small className="text-red-700">*</small></label>
                                                <select name="is_nonveg" id="" className='input' value={productData?.is_nonveg} onChange={handleAddFromData}>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className='label'>Product Unit <small className="text-red-700">*</small></label>
                                                <select name="content_type" value={productData?.content_type} id="" className='input' onChange={handleAddFromData}>
                                                    <option value="">--Select Unit--</option>
                                                    <option value="grams">Grams</option>
                                                    <option value="liters">Liters</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className='label'>Product Weight<small className="text-red-700">*</small></label>
                                                <input type="number" placeholder='weight' name='weight' className='input' value={productData?.weight} onChange={handleAddFromData} />
                                            </div>
                                            <div>
                                                <div className='flex items-center space-x-2'>
                                                    <input type="checkbox" placeholder='charge_incl' id='charge_incl' name='charge_incl' value={productData?.charge_incl} onChange={handleCheckBox} />
                                                    <label htmlFor='charge_incl' className='cursor-pointer select-none'>Product Delivery Charges Inclusive <small className="text-red-700">*</small></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                        <h1 className='font-semibold text-2xl pb-3'>Search Engine Optimization</h1>
                                    </div>
                                    <div>
                                        <div className='mb-2'>
                                            <label className='label'>Meta Title</label>
                                            <input type="text" placeholder='Meta Title' name="seo" data-name='meta_title' className='input' value={productData?.seo?.['meta_title']} onChange={handleAddFromData} />
                                        </div>
                                        <div className='mb-2'>
                                            <label className='label'>Meta Keywords</label>
                                            <input type="text" placeholder='Meta Keywords' name="seo" data-name='meta_keywords' className='input' value={productData?.seo?.['meta_keywords']} onChange={handleAddFromData} />
                                        </div>
                                        <div className='mb-2'>
                                            <label className='label'>Meta Description</label>
                                            <input type="text" placeholder='Meta Description' name="seo" data-name='meta_description' className='input' value={productData?.seo?.['meta_description']} onChange={handleAddFromData} />
                                        </div>
                                    </div>
                                </div>

                                {/* <ProductDetails
                                    productData={productData}
                                    setProductData={setProductData}
                                /> */}

                                <div className='grid grid-cols-2 gap-4'>
                                    <div>
                                        <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                            <div className='pb-3'>
                                                <h1 className='font-semibold text-2xl'>Thumbnail of product</h1>
                                                <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                            </div>
                                        </div>
                                        <div className=' space-y-4'>
                                            <div className=' space-y-4'>
                                                <FileUploader name="product_thumbnail" types={fileTypes} handleChange={(e) => handleUploadFile([e], 'thumbnail')} classes="file-uploader" hoverTitle='Drop here' />
                                                {productData?.thumbnail?.image_url && <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                    <img src={productData?.thumbnail?.image_url} alt="preview" />
                                                </div>}
                                                <div className='mb-2'>
                                                    <label className='label'>Thumbnail Image Alt Tag</label>
                                                    <input type="text" placeholder='Thumbnail Image Alt Tag' value={productData?.thumbnail?.image_alt} onChange={handleChangeThumbImageAlt} name="image_alt" className='input' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                            <div className='pb-3'>
                                                <h1 className='font-semibold text-2xl'>Images of product</h1>
                                                <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center mt-12'>
                                            <Button onClick={handleOpenUploadModal} variant="contained" color='success' className='primaryBtn-contained'>Add Images</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center my-6'>
                                    {postProductDataToServer.isLoading ?
                                        <Spinner /> :
                                        <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                            Save Product
                                        </Button>}
                                </div>
                            </form>
                        </div>
                    )
                }

            </div>
            <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
                    <div className=' max-w-5xl mx-auto bg-white'>
                        <div className=' divide-y'>
                            <div className="head-modal">
                                <div className='flex items-center space-x-4 px-6'>
                                    <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                                        <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                    </div>
                                    <h2 className='heading text-3xl'>Upload Product Images</h2>
                                </div>
                            </div>
                            <div className="body-modal py-4 px-6">
                                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative cursor-pointer'>
                                    <div className='imagePreview__container relative z-10'>
                                        {/* <div className="grid grid-cols-4 gap-4 "> */}
                                        <ReactSortable className="grid grid-cols-4 gap-4" list={productData?.additional_product_image} setList={setProductDetailsList}>
                                            {productData?.additional_product_image?.map((img, idx) => {
                                                return <div key={idx}>
                                                    <div className=' w-full overflow-hidden border p-2 relative cursor-default' key={idx}>
                                                        <div className='w-6 h-6 cursor-pointer float-right'>
                                                            <svg onClick={() => handleRemoveProductImage(img.image_id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                        </div>
                                                        <img src={img.image_url} alt="img" className='w-full h-40 object-contain' />
                                                        <div className='my-2'>
                                                            <label className='label'>Product {idx + 1} Alt Tag</label>
                                                            <input type="text" value={img.image_alt} placeholder={`Product ${idx + 1} Alt Tag`} name="image_alt" className='input' />
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                            <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                                                <GrAdd className=' w-16 h-16 text-gray-400' />
                                                <small>Add Product Image</small>
                                            </div>
                                        </ReactSortable>
                                        {/* </div> */}
                                    </div>
                                    <input type="file" className='hidden' ref={productImageUpload} onChange={(e) => handleUploadFile(e.target.files, 'additional_product_image')} multiple accept="image/jpeg, image/png, image/jpg" />
                                </div>
                            </div>
                            <div className="foot-modal py-4 text-center">
                                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditProduct