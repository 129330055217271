import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { FcFolder, FcOpenedFolder } from 'react-icons/fc'
import { GoFileDirectory } from 'react-icons/go'
const Tree = ({ data,onPress }) => {
    const StyledTreeItem = styled((props) => (
        <TreeItem {...props} />
    ))(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));
    const TreeViewRender = (data) => {
        const isChildren = data?.subCategory !== null;
        if (isChildren) {
            return (
                <StyledTreeItem key={data.name} nodeId={data.name} label={data.name}>
                    {data?.subCategory?.map((node, idx) => TreeViewRender(node))}
                </StyledTreeItem>
            );
        }
        return <StyledTreeItem  key={data.name} nodeId={data.name} label={data.name} />
    }
    return (
        <>
            <TreeView
                aria-label="customized"
                defaultExpanded={['1']}
                onNodeSelect={onPress}
                defaultCollapseIcon={<FcOpenedFolder className='w-6 h-6' />}
                defaultExpandIcon={<FcFolder className='w-6 h-6' />}
                defaultEndIcon={<GoFileDirectory className='w-4 h-4 text-gray-500' />}
                sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto', }}
            >
                {data?.map((listData, idx) => {
                    return TreeViewRender(listData)
                })}
            </TreeView>

        </>
    )
}

export default Tree

